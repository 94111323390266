import React, { useEffect, useState } from 'react';
import './App.css';
import AppWrapperRoute from './routes/index';
import { MainAppDataContext, defaultUserValue, MainAppDataContextType } from './context/MainAppDataContext';
import { ApolloProvider } from '@apollo/client';
import { ApolloClientContext } from './context/ApolloClientContext';
import styled from 'styled-components';
import { NavBar } from 'antd-mobile';
import { getGraphQLClient } from './graphql';
import { PODO_STORE, STORAGE_KEYS } from './store';

export const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  max-width: 100vw;
  /* height: 100vh; */
`;

export const HeaderText = styled(NavBar)`
  height: 46px;
`;

const App = (): JSX.Element => {
  const productionUrl = 'https://podo-test.great-supper.com/podo_new/';
  const subAppApiClient = getGraphQLClient(new URL('graphql', productionUrl).href);
  const [mainAppdataState, setMainAppData] = useState<MainAppDataContextType>(defaultUserValue);
  useEffect(() => {
    async function init() {
      const scode = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Scode);
      const gender = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Gender);
      const id = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Id);
      const name = await PODO_STORE.getItem(STORAGE_KEYS.PODO.Name);
      setMainAppData({
        userInfo: {
          sCode: scode as string,
          gender: gender as string,
          id: id as number,
          name: name as string,
        },
      });
    }
    init();
  }, []);
  return (
    <React.Suspense fallback={null}>
      <MainAppDataContext.Provider value={{ ...mainAppdataState, setMainAppData }}>
        <ApolloClientContext.Provider value={{ subAppApiClient }}>
          <ApolloProvider client={subAppApiClient}>
            <LayoutContainer>
              <AppWrapperRoute />
            </LayoutContainer>
          </ApolloProvider>
        </ApolloClientContext.Provider>
      </MainAppDataContext.Provider>
    </React.Suspense>
  );
};

export default App;
